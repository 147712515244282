.accordion {
  display: flex;
  justify-content: center;
  width: 100%;

  .cmp-accordion {
    width: 100%;
    padding: 0 1.2rem;
    @media screen and (min-width: $screen-md-lg) {
      max-width: 52rem;
      padding: 0;
    }
  }

  .cmp-container {
    padding-bottom: 1.1rem;
  }

  .cmp-accordion__title {
    align-self: center;
    color: $platform-dark-blue;
    font-size: 18px;
  }

  .cmp-accordion__button {
    display: flex;
    justify-content: space-between;
    background: inherit;
    padding: 22px 0 17px 0;
    outline: none;
  }

  .cmp-accordion__item {
    border-bottom: 2px solid $platform-light-gray-outline;
  }

  .cmp-accordion__icon {
    align-self: center;
    position: relative;
    height: 19px;
    width: 19px;
    border: solid 2px $platform-full-width-btn;
    border-radius: 50%;
    margin-right: 10px;
  }

  .cmp-accordion__icon:after {
    content: "";
    display: block;
    width: 0.49rem;
    background: $platform-dark-blue;
    height: 1.5px;
    top: 0px;
    bottom: 0;
    left: 1px;
    right: 0;
    margin: auto;
    transform: rotate(90deg);
    position: absolute;
  }

  .cmp-accordion__icon:before {
    content: "";
    display: block;
    background: $platform-dark-blue;
    bottom: 0;
    right: 0;
    margin: auto;
    transform: rotate(-90deg);
    position: absolute;
    top: 0;
    left: 55%;
    width: 1.5px;
    height: 54%;
    margin-left: -1px;
  }

  .cmp-accordion__icon:before,
  .cmp-accordion__icon:after {
    background-color: $platform-dark-navy-blue;
    transition: transform 0.2s ease-out;
  }

  svg.accordionExpandIcon {
    display: inline;
  }

  svg.accordionCollapseIcon {
    display: none;
  }

  // hide accordion shelves
  .cmp-accordion__button--expanded {
    .cmp-accordion__icon {
      &:before,
      &:after {
        transition: transform 0.2s ease-out;
        background-color: $platform-dark-navy-blue;
      }

      &:before {
        transform: rotate(90deg);
      }

      &:after {
        transform: rotate(180deg);
      }
    }

    svg.accordionExpandIcon {
      display: none;
    }

    svg.accordionCollapseIcon {
      display: inline;
    }
  }
}
