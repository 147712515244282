$platform-input-length-md: 474px;
$input-height: 50px;
$icon-width: 36px;

.cmp-form-text {
  label {
    display: block;
    padding: 5px 0;
    font-weight: 500;
    font-size: 18px;
  }

  .cmp-form-text__text-container {
    max-width: 100%;
    position: relative;

    .icon {
      opacity: 0;
      pointer-events: none;
      position: absolute;
      top: 0;
      right: 0;
      z-index: $z-level0;
      width: $icon-width;
      height: 100%;
      transition: opacity 250ms ease-in;
    }

    &.input-invalid .icon {
      background-image: $icon-x;
      background-repeat: no-repeat;
      background-position-x: center;
      background-position-y: center;
      background-size: 1.5rem;
      opacity: 1;
    }

    &.confirmed-input .icon {
      background-image: $icon-check;
      background-repeat: no-repeat;
      background-position-x: center;
      background-position-y: center;
      background-size: 1rem;
      opacity: 1;
    }
  }

  input {
    height: 50px;
    padding: 5px 10px;
    font-size: 16px;
    padding-bottom: 5px;
    width: 100%;
    @media screen and (min-width: $platform-input-length-md) {
      width: $platform-input-length-md;
    }
  }

  textarea {
    font-size: 16px;
    letter-spacing: 0;
    padding: 5px 10px;
    height: 129px;
    width: 100%;
    @media screen and (min-width: $platform-input-length-md) {
      width: $platform-input-length-md;
    }
  }

  .invalid-input-message {
    display: none;
    height: 18px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.5px;
    line-height: 18px;

    &.display-block {
      display: block;
    }
  }

  @media screen and (max-width: $screen-sm) {
    font-size: 16px;
    font-weight: 500;

    input {
      height: 50px;
      padding: 5px 10px;

      &.invalid-input {
        background-image: $icon-x;
        background-repeat: no-repeat;
        background-position-x: right;
        background-position-y: center;
        background-size: 1.5rem;
      }
    }

    textarea {
      height: 153px;
    }
  }
}
