$navigator-height: 50px;

html {
  /* Added to HTML element when page navigator is "floating" to prevent
   obscuring content when linking to a section of the page */
  scroll-padding-top: var(--page-navigator-scroll-top, 0px);
}

.cbg-cmp-page-navigator {
  box-sizing: border-box;
  overflow-x: auto;
  overflow: initial;
  display: flex;
  align-items: center;
  height: $navigator-height;
  position: absolute;
  z-index: 10;
  border: 0px;

  .button-home {
    padding: 8px 30px;
  }

  &.full-screen {
    background-color: $porcelain;
    width: 100%;
  }

  @media screen and (max-width: 769px) {
    position: static;
  }

  .navigator {
    display: flex;
    overflow: scroll;
  }

  .cmp-navigator-retailer--drop-down {
    border: 0;
    display: flex;
    align-items: center;
    &.style-for-icon {
      select {
        text-align: center;
        background-color: $platform-white;
        padding: 3px 20px 3px 3px;
        border-radius: 7px;
      }
      svg {
        position: relative;
        right: 16px;
        width: 10px;
        height: 30px;
        padding-top: 1px;
        pointer-events: none;
      }
    }

    .options-label {
      margin-right: 6px;
    }
  }

  .global-retailer-container {
    justify-content: center;
    display: flex;
    width: 50%;
    @media screen and (min-width: $screen-md) {
      justify-content: start;
      padding-left: 30px;
      width: 50%;
    }

    @media screen and (min-width: $screen-md-lg) {
      padding-left: 34px;
    }

    @media screen and (min-width: $screen-lg-md) {
      padding-left: 90px;
    }

    @media screen and (min-width: $screen-xl) {
      padding-left: 130px;
    }
  }

  .global-retailer {
    position: relative;
    padding: 0 0.8em;
    width: 100%;
    @media screen and (min-width: $screen-md) {
      min-width: 15rem;
    }
  }

  .global-retailer-list__item {
    flex-flow: column;
    list-style: none;
  }

  .global-retailer-title-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 100%;
  }

  .list-default-close-icon {
    background-image: url("../resources/images/svgs/dropdown-arrow.svg");
    width: 12px;
    height: 12px;
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
  }

  .global-retailer-list__container {
    position: absolute;
    background-color: white;
    z-index: 10;
    top: 2.3rem;
    height: 7.9rem;
    left: 0px;
    width: 100%;
    cursor: pointer;
    @media screen and (min-width: $screen-md) {
      left: 3px;
      width: 22.2rem;
    }
  }

  .global-retailer-list {
    list-style: none;
    text-align: center;
    padding: 0;
    margin: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .global-retailer-list__title {
    min-width: 8.5rem;
  }

  .global-retailer-list__link {
    text-decoration: none;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
  }

  .global-retailer-cta {
    margin-top: 10px;
    max-width: 19.3rem;
    min-width: 19.3rem;
    min-height: 3.1rem;
    max-height: 3.1rem;
    display: flex;
    align-items: center;
    background: transparent !important;
  }

  .global-retailer-cta:hover {
    color: #1a325d !important;
  }

  .global-retailer-items {
    margin-top: 10px;
  }

  .retailer__overlay {
    position: absolute;
    left: 0;
    top: 3.1rem;
    width: 100%;
    height: 970vh;
    opacity: 0.7;
    background-color: $platform-black;
    z-index: $z-level1;
    @media screen and (min-width: $screen-sm) {
      height: 100vh;
    }
  }

  .retailer__loading {
    position: absolute;
    left: 0;
    top: 8.1rem;
    width: 100%;
    height: 100vh;
    background-color: $white;
    box-shadow: 5px 10px 18px $plaform-gray-fill;
    z-index: $z-level1;
    text-align: center;
    padding-top: 134px;
  }

  @media screen and (min-width: 769px) {
    //margin-left: 4%;
    height: $navigator-height;
    //width: 100%;
    overflow: initial;
    z-index: 10;
  }
}
.item-info-holder {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  @media screen and (min-width: $screen-sm) {
    justify-content: flex-start;
  }
}

.current-list-item {
  padding: 0px 0.1rem;
}

.stop-scrolling {
  height: 100%;
  overflow: hidden;
  overflow-x: unset;
}

.loading {
  position: absolute;

  &:after {
    content: "";
    display: block;
    width: 40px;
    height: 40px;
    border: 3px solid;
    border-radius: 40px;
    border-color: $brand-light-blue $brand-light-blue transparent;
    animation: 1.5s rotating linear infinite;
    top: calc(30% - 8px);
    left: 40rem;
  }
}

.flex-loader {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column-reverse;
}

.cbg-cmp-utility-nav {
  &.desktop-only {
    .cbg-cmp-utility-nav-wrapper {
      display: block;

      ul {
        &.utility-nav-items {
          display: none;
          @media screen and (min-width: $tablet-breakpoint) {
            display: flex;
          }
        }
      }

      .utility-left-side {
        width: 100%;
        display: block;
        @media screen and (min-width: $tablet-breakpoint) {
          width: 400px;
        }
      }

      .utility-nav-items {
        justify-content: flex-end;
        display: none;
        @media screen and (min-width: $tablet-breakpoint) {
          display: flex;
        }
      }
    }
  }
}
