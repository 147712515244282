$screen-xxl: 1800px;
$screen-xl: 1400px;
$screen-lg: 1200px;
$screen-lg-md: 1100px;
$screen-md-lg: 992px;
$screen-md: 800px;
$screen-sm-billboard: 768px;
$screen-sm-md: 650px;
$screen-sm: 420px;
$screen-xs: 320px;
