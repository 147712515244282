$platform-disclaimer-length-sm: 474px;
$platform-disclaimer-length-md: 574px;
$platform-input-length-md: 474px;

.cmp-form-options {
  border: none;
  font-size: $font-size;
  padding: 0;
  margin: 0;

  .cmp-form-options__field--drop-down {
    height: 3em;
    padding: 12px;
    margin: 0;
    width: 100%;

    @media screen and (min-width: $platform-input-length-md) {
      width: $platform-input-length-md;
    }
  }

  .cmp-form-options__label {
    margin-bottom: 11px;
    display: inline-block;
    font-size: $font-size;
    @media screen and (min-width: $screen-sm) {
      font-size: 18px;
    }
  }

  .cmp-form-options__field-description {
    font-size: $font-size;
    //styling update
    p {
      margin: 0;
    }
    @media screen and (min-width: $screen-sm) {
      font-size: 18px;
    }
  }

  @media screen and (min-width: $screen-sm) {
    font-size: 18px;
  }

  .invalid-input-message {
    font-size: $font-size;
    font-weight: 500;
    letter-spacing: 0.5px;
    padding-top: 1rem;
    display: none;

    &.display-block {
      display: block;
    }
  }
}

.cmp-form-options--checkbox,
.cmp-form-options--radio {
  display: flex;
  flex-direction: column;

  .cmp-form-options__field-label {
    cursor: pointer;
    display: inline-flex;
    position: relative;
    align-items: center;
  }
}

.cmp-form-options--radio {
  input {
    border-radius: 50%;
    height: 24px;
    width: 24px;
    background-image: none;
  }
}

.cmp-form-options__field--radio:checked::before {
  content: "";
  width: 18px;
  height: 18px;
  border-radius: 50%;
  position: absolute;
  top: 3px;
  left: 8px;
}

.cmp-form-options__field {
  height: fit-content;
  width: 100%;
  border: 2px solid $platform-gray;
  display: flex;
  margin-top: 0;
  background-image: $icon-dropdown;
  background-repeat: no-repeat;
  background-position: center right 12px;
  cursor: pointer;
  appearance: none;
}

.cmp-form-options__field-label:not(:first-of-type) {
  margin-top: 8px;
}

.cmp-form-options__field-label > input {
  background-size: 20px 20px;
  height: 20px;
  width: 20px;
  margin-right: 10px;
}

.cmp-form-options__legend {
  margin-bottom: 12px;
}

.cmp-form-options__field--checkbox {
  appearance: none;
  background-color: transparent;
  border: 2px solid $platform-background-light-gray-scheme;
  cursor: pointer;
  height: 1.2rem;
  outline: none;
  transition-duration: 0.15s;
  width: 1.2rem;
}

.cmp-form-options__field--checkbox:checked::before {
  color: $platform-full-width-btn;
  content: "\2713";
  display: block;
  left: 0.4rem;
  position: absolute;
  text-align: center;
  border: none;
  top: 1px;
}

select {
  appearance: none;
}

.disclaimer-home {
  margin-top: 15px;
  .cmp-form-options__field-description {
    width: 95%;
  }

  .cmp-form-options__field--checkbox {
    margin-top: 5px;
  }

  &.cmp-form-options--checkbox {
    .cmp-form-options__field-label {
      align-items: normal;
    }
  }

  @media screen and (min-width: $screen-sm) {
    max-width: $platform-disclaimer-length-sm;
  }
}
