$platform-button-border-width: medium;
$platform-button-padding-top: 0.8em;
$platform-button-padding-sides: 4em;
//Trigger
body {
  @media screen and (max-width: $screen-md) {
    position: static;
  }
}

html,
body {
  @media screen and (max-width: $screen-md) {
    overflow-x: hidden;
  }
}

//kick off build
.left-align {
  a {
    float: left;
    margin: 15px;
  }
}

.right-align {
  a {
    float: right;
    margin: 15px;
  }
}

.center-align {
  a {
    margin: 15px auto;
    display: block;
    width: 50%;
  }
}

.card-container-home {
  &.flex-type-button {
    .each-row {
      flex-direction: row;
      width: 100%;
      @media screen and (min-width: $screen-sm-md) {
        width: 55%;
      }
      @media screen and (min-width: $screen-lg) {
        width: 75%;
      }
      @media screen and (min-width: $screen-xl) {
        width: 100%;
      }
    }
  }
}

.button-home {
  margin: 5px 0;
  justify-content: center;
  display: flex;

  @media screen and (min-width: $screen-sm-md) {
    padding: 8px 30px;
  }

  &:disabled {
    opacity: 38%;
  }

  img {
    display: inline;
    width: 0.6em;
  }

  .button-content {
    height: 25px;
    display: block;
    @media screen and (min-width: $screen-sm) {
      display: flex;
    }
    .actual-icon {
      @media screen and (min-width: $screen-sm-md) {
        display: inline-block;
        width: 16px;
        margin-top: 2px;
        margin-left: 5px;
      }
      @media screen and (min-width: $screen-md) {
        display: block;
        width: 30px;
      }
    }
    &.icon {
      svg {
        height: 20px;
        width: 20px;
        margin-bottom: 4px;
        margin-left: 6px;

        &.external {
          width: 12px;
          height: 12px;
          transform: translate(4px, 4px);
          @media screen and (max-width: $screen-md) {
            transform: translate(7px, 5px);
          }
          @media screen and (max-width: $screen-sm) {
            transform: translateX(5px);
          }
        }
      }
    }
  }
}

.button,
.each-text-area {
  .button-home {
    @media screen and (max-width: $screen-md) {
      margin: 0;
      width: 100%;
    }

    .icon-spacer {
      @media screen and (max-width: $screen-md) {
        display: none;
      }
    }
    .button-text {
      //margin: 4px auto;
      @media screen and (max-width: $screen-md) {
        margin: auto;
      }
    }
  }
}

.second-button {
  .button-home {
    margin-top: 20px;
  }
}

.button-content {
  margin: auto;
  display: block;

  svg {
    vertical-align: middle;
  }

  .icon-spacer {
    height: 20px;
    width: 30px;
    vertical-align: middle;
  }
  .icon-width {
    width: 20px !important;
  }
}

.modal-button,
button.button-home {
  border-radius: 3em;
  border-width: 0;
  font-size: $font-size;

  &.style-as-link {
    border-radius: 0;
    padding: 0.8em;
    background: none;
  }
}

button,
a.button-link.button-home {
  border-radius: 3em;
  border-width: 0;
  padding: 0.8em;
  width: 100%;
  font-size: $font-size;
  cursor: pointer;
  .cbg-color-chip__text.add-text {
    span.coty-favorite-heart {
      &:before {
        background-image: none;
        content: none;
      }
      &:after {
        background-image: none;
        content: none;
      }
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      svg {
        height: 25px;
        width: 25px;
        margin-right: 10px;
        fill: none;
        stroke: $platform-text-color-blue;
      }
    }
  }
  .cbg-color-chip__text.remove-text {
    span.coty-favorite-heart {
      &:before {
        background-image: none;
        content: none;
      }
      &:after {
        background-image: none;
        content: none;
      }
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      svg {
        height: 25px;
        width: 25px;
        margin-right: 10px;
        fill: $platform-text-color-blue;
        stroke: $platform-text-color-blue;
      }
    }
  }

  @media screen and (min-width: $screen-md) {
    width: auto;
  }
}

.button--wrapper-outer {
  width: fit-content;
  display: inline;
}

.button--wrapper-inner {
  @media screen and (min-width: $screen-md) {
    width: fit-content;
  }
}

@media screen and (min-width: $screen-md) {
  .card-alignment-left,
  .button-alignment-left {
    .button--wrapper-inner {
      margin-right: auto;
    }
  }

  .card-alignment-right,
  .button-alignment-right {
    .button--wrapper-inner {
      margin-left: auto;
    }
  }

  .card-alignment-center,
  .button-alignment-center {
    .button--wrapper-inner {
      margin-right: auto;
      margin-left: auto;
    }
  }
}

.pill-styling,
a.pill-styling.button-link.button-home {
  border-radius: 1.6rem;
  padding: 8px 10px;
  display: flex;
  padding-left: 10px;
  outline: none;
  cursor: pointer;
  width: 160px;
  height: auto;
  justify-content: left;

  @media screen and (min-width: $screen-sm) {
    padding: 5px 10px;
    width: 176px;
  }

  &.no-pill-needed {
    .pill-circle {
      display: none;
    }
    .button-text {
      margin: 4px auto;
    }
  }

  .pill-circle {
    width: 24px;
    height: 24px;
    border-radius: 100%;
    margin: 0 10px 0 0;

    @media screen and (min-width: $screen-sm) {
      width: 40px;
      height: 40px;
      padding: 0;
    }
  }

  .button-content {
    height: auto;
    align-items: center;
    display: flex;
    width: 100%;
    padding: 6px;
  }
}

.button {
  .button--wrapper-inner {
    .button-link {
      margin-left: auto;
      margin-right: auto;
    }
  }
}
