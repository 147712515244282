.carousel {
  .advanced {
    display: flex;

    .cmp-carousel__indicator {
      width: 1.9rem;
      height: 1.9rem;
      background-color: #f80;
      border: 3px solid $platform-white;
      border-radius: 18px;
      box-shadow: 0 0 2px $platform-black;

      &.hover {
        background-color: blue;
        box-shadow: 0 0 2px $platform-white;
      }
    }

    .cmp-carousel__indicator--active {
      border: 3px solid $plaform-gray-fill;
      border-radius: 15px;
      box-shadow: 0 0 2px $platform-black;
      height: 2.4rem;
      width: 2.4rem;
      outline: none;
    }
  }

  .cmp-carousel__actions {
    display: flex;
  }

  .hideBasic {
    display: none;
  }

  .showBasic {
    display: flex;
  }

  .card {
    width: 100%;
    margin: auto;

    .cbg-cmp-card__image-container {
      width: auto;

      img {
        width: 100%;
      }
    }

    .card-image-div {
      width: 100%;
      height: fit-content;
    }
  }

  .simple-numbering {
    display: flex;
    margin: 1.6rem;

    &.cmp-carousel__indicators {
      display: flex;

      .current {
        padding-right: 2px;
      }
    }

    .cmp-carousel__indicator--active {
      background-color: inherit;
      outline: none;
    }

    .carousel-number {
      display: none;

      &.cmp-carousel__indicator--active {
        outline: none;
      }
    }
  }

  .carousel-number--active {
    display: block;
    outline: none;
  }

  .hide-visually {
    margin: -1px;
    padding: 0;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    clip: rect(0, 0, 0, 0);
    position: absolute;
  }

  .indicator-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: stretch;

    .indicator-button {
      --borderRadius: 100%;
      --hex: #c2cac0;
      --borderColor: transparent;

      display: block;
      position: relative;
      cursor: pointer;
      width: 1.9rem;
      height: 1.9rem;
      padding: 0;
      border-radius: var(--borderRadius);
      margin: 14px 17px 0 0;
      border: 0;
      outline: none;

      &::before {
        content: "";
        display: block;
        width: calc(100% + 8px);
        height: calc(100% + 8px);
        border-radius: var(--borderRadius);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 2px solid var(--borderColor);
      }

      &:hover,
      &:focus {
        --borderColor: $plaform-gray-fill;
        outline: none;
      }

      &[aria-selected="true"] {
        --borderColor: $plaform-gray-fill;
        outline: none;
      }

      @media screen and (min-width: $screen-md) {
        margin-top: 0px;
      }
    }

    @media screen and (min-width: $screen-md) {
      padding: 1.25rem;
      padding-right: 0;
    }
  }

  .tns-outer [aria-controls],
  .tns-outer [data-action] {
    cursor: pointer;
  }

  .tns-slider {
    -webkit-transition: all 0s;
    -moz-transition: all 0s;
    transition: all 0s;
  }

  .tns-horizontal.tns-subpixel {
    white-space: nowrap;
  }

  .tns-horizontal.tns-subpixel > .tns-item {
    display: inline-block;
    vertical-align: middle;
    white-space: normal;
  }

  .tns-horizontal.tns-carousel.tns-no-subpixel > .tns-item {
    margin-right: -100%;
  }

  .tns-gallery > .tns-item {
    position: absolute;
    left: -100%;
    display: none;
    -webkit-transition: transform 0s, opacity 0s;
    -moz-transition: transform 0s, opacity 0s;
    transition: transform 0s, opacity 0s;
  }

  .tns-gallery > .tns-slide-active {
    position: relative;
    left: auto !important;
  }

  .tns-gallery > .tns-moving {
    -webkit-transition: all 0.25s;
    -moz-transition: all 0.25s;
    transition: all 0.25s;
  }

  .tns-autowidth {
    display: inline-block;
  }

  .tns-lazy-img {
    -webkit-transition: opacity 0.6s;
    -moz-transition: opacity 0.6s;
    transition: opacity 0.6s;
    opacity: 0.6;
  }

  .tns-lazy-img.tns-complete {
    opacity: 1;
  }

  .tns-ah {
    -webkit-transition: height 0s;
    -moz-transition: height 0s;
    transition: height 0s;
  }

  .tns-ovh {
    overflow: hidden;
  }

  .tns-transparent {
    opacity: 0;
    visibility: hidden;
  }

  .tns-fadeIn {
    opacity: 1;
    filter: alpha(opacity=100);
    z-index: 0;
  }

  .tns-normal,
  .tns-fadeOut {
    opacity: 0;
    filter: alpha(opacity=0);
    z-index: -1;
  }

  .tns-vpfix {
    white-space: nowrap;
  }

  .tns-vpfix > div,
  .tns-vpfix > li {
    display: inline-block;
  }

  .tns-t-subp2 {
    margin: 0 auto;
    width: 310px;
    position: relative;
    height: 10px;
    overflow: hidden;
  }

  .tns-t-ct {
    width: 2333.3333333%;
    width: -webkit-calc(100% * 70 / 3);
    width: -moz-calc(100% * 70 / 3);
    width: calc(100% * 70 / 3);
    position: absolute;
    right: 0;
  }

  .tns-t-ct:after {
    content: "";
    display: table;
    clear: both;
  }

  .tns-t-ct > div {
    width: 1.4285714%;
    width: -webkit-calc(100% / 70);
    width: -moz-calc(100% / 70);
    width: calc(100% / 70);
    height: 10px;
    float: left;
  }

  .tns-nav {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    grid-auto-flow: row;
    width: 100%;
    margin: 0 4px;

    [aria-controls] {
      width: 2.2rem;
      height: 2.2rem;
      padding: 0;
      border-radius: 50%;
      background: #ddd;
      border: 0;
      outline: none;
    }

    .tns-nav-active {
      border-radius: 50%;
      box-shadow: 0 0 0 2px $platform-white, 0 0 0 3.6px $platform-gray-text;
      width: 37.2px;
      height: 37.2px;
      outline: none;
      @media screen and (min-width: $screen-md) {
        box-shadow: 0 0 0 2px $platform-white, 0 0 0 4px $platform-gray-text;
      }
    }

    button:hover {
      @media screen and (min-width: $screen-md-lg) {
        box-shadow: 0 0 0 2px $platform-white,
          0 0 0 4px $platform-image-upload-border;
      }
    }

    @media screen and (min-width: 285px) {
      grid-template-columns: repeat(6, 1fr);
    }
    @media screen and (min-width: $screen-sm) {
      gap: 7px;
      grid-auto-flow: column;
      grid-template-columns: auto;
      width: fit-content;
    }
    @media screen and (min-width: $screen-md) {
      gap: 13px;
    }
    @media screen and (min-width: $screen-md-lg) {
      gap: 17px;
    }
  }

  .carousel-icon-btn {
    height: 100%;
    display: inline-block;
    width: 1rem;
  }

  .tns-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 0;
    @media screen and (min-width: $screen-md) {
      padding: 1.25rem 0;
    }
  }

  .tns-controls button {
    background-color: transparent;
    border: 2px solid $platform-full-width-btn;
    padding: 0;
    width: 3.5rem;
    height: 3.5rem;
    outline: none;

    svg.backButtonIcon {
      display: inline;
    }

    svg.forwardButtonIcon {
      display: inline;
    }
  }

  .tns-controls button:hover {
    --borderColor: $plaform-gray-fill;
    outline: none;
  }

  .tns-visually-hidden {
    left: 0;
    padding: 0 15px;
    display: none;
  }

  .cmp-carousel__action.cmp-carousel__action--previous,
  .cmp-carousel__action.cmp-carousel__action--next {
    svg.cmp-carousel__action-icon.has-authored-icon {
      background-image: none;
      max-width: 3.1rem;
    }
  }

  .advanced-numbers {
    display: none;
    @media screen and (min-width: $screen-md) {
      display: inline-block;
    }
  }

  .carousel-number--active .cmp-carousel__indicator--active {
    display: none;
  }
}

@media screen and (max-width: 460px) {
  .cmp-carousel__content {
    .cbg-cmp-card__swatch-container {
      width: 100px;
      min-height: auto;
      height: 154px;

      .cbg-color-swatch {
        width: 100px;
        height: 84px;
      }

      .cbg-colorCard__info {
        padding: 6px 0 6px 6px;
      }

      .cbg-colorCard__color-name,
      .cbg-colorCard__color-id {
        font-size: 10px;
      }
    }

    .cbg-cmp--tag-emblem.cbg-cmp--tag-emblem__vertical-bottom
      .cbg-cmp--tag-emblem-inner {
      margin: 0 0 4px 4px;
    }
  }
}
