//== Color
$platform-white: #ffffff;
$platform-gray: #aaa;
$platform-black: black;
$platform-cream: #f6f8f8;
$platform-dark-blue: #1a335e;
$platform-gray-text: #5b6770;
$platform-dark-gray: #333333;
$plaform-gray-fill: #5b6770;
$platform-dark-navy-blue: #1a325d;
$platform-full-width-btn: #62b5e5;
$platform-dark-gray-2: #495057;
$platform-light-gray-outline: #eeeeee;
$platform-image-upload-border: #ced1d4;
$platform-wall-dropdown-menu-border: #92959d;
//= Colors for Valspar
$valspar-white: #ffffff;
$valspar-light-gray: #ced3dc;
$valspar-background-blue: #1a335e;
$valspar-cream: #f6f8f8;
$valspar-dark-gray: #5d676f;
$valspar-black: black;
$valspar-dark-blue: #0f2e5a;

$brand-light-blue: #76b4e0;
$brand-dark-blue: $platform-dark-blue;
$brand-light-gray: #979797;

// Normal mode
$color-foreground: #202020;
$color-background: #ececec;
$color-link: #2020e0;

//Brand Themes
$platform-font-light-scheme: $platform-black;
$platform-font-medium-scheme: $platform-gray;
$platform-font-dark-scheme: $platform-white;
$platform-font-gray-scheme: #333333;
$platform-background-light-scheme: $platform-white;
$platform-background-medium-scheme: $platform-cream;
$platform-background-dark-scheme: $platform-black;
$platform-background-gray-scheme: #eeeeee;
$platform-background-light-gray-scheme: #dfdfdf;

// Text Colors
$platform-text-color-blue: #002e5d;

// Box Shadow
$box-shadow-gray: $platform-dark-gray;
$box-shadow-opacity: 0.08;

$botticelli: #c4d9e3;
$brand-accent-main: #1a335e;
$bali-hai: #8c99ae;
$color-input-text: #000;
$icon-arrows-select: "data:image/svg+xml,%3Csvg enable-background='new 0 0 100 100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23010101'%3E%3Cpath d='m75 26.5c0-.9-.3-1.6-.9-2.2l-21.9-21.8c-.6-.6-1.3-.9-2.2-.9s-1.6.3-2.2.9l-21.9 21.8c-.6.7-.9 1.4-.9 2.2s.3 1.6.9 2.2 1.3.9 2.2.9h43.7c.9 0 1.6-.3 2.2-.9.7-.6 1-1.3 1-2.2z'/%3E%3Cpath d='m74 70.5c-.6-.6-1.3-.9-2.2-.9h-43.7c-.9 0-1.6.3-2.2.9s-.9 1.3-.9 2.2.3 1.6.9 2.2l21.9 21.9c.6.6 1.3.9 2.2.9s1.6-.3 2.2-.9l21.9-21.9c.6-.6.9-1.3.9-2.2 0-.8-.3-1.6-1-2.2z'/%3E%3C/g%3E%3C/svg%3E";

$brand-ratings-accent-color: $bali-hai;
$brand-ratings-row-bg-color: $botticelli;
$brand-ratings-hover-outline: $bali-hai;
$brand-ratings-modal-outline: $botticelli;
$brand-ratings-border: 2px solid $brand-ratings-modal-outline !important;
$brand-ratings-text-underline-color: $botticelli;

$gray2: #333;
$teal: #fff;
$teal1: #c4d9e3;
$orange: #fff;
$white: #fff;
$black: #000;
$dark-gray: #383838;
$charcoal-gray: #333333;
$porcelain: #e6e8e9;
$cement-gray: #f7f7f7;
