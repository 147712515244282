$wall-sidebar-width: 18rem;

.cbg-cmp-wall__filter-applied {
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  border: 2px solid lightgray;
  border-radius: 2em;
  opacity: 1;
  transition: opacity 0.25s ease-in-out;
  padding: 10px 12px;
  color: darkgray;
  cursor: pointer;
  background: none;
  font-size: 16px;
  font-weight: 100;
  width: auto;

  &.hidden {
    opacity: 0;
  }

  /* Do not Remove the below. Removing will cause margin to be added to p tags,
    thereby messing up applied filter's spacing */
  p {
    margin: 0;
  }

  // Remove Filter Button
  .button--filter-remove {
    border-radius: 1rem;
    background-color: lightgray;
    font-weight: 100;
    padding: 4px;
    width: 18px;
    height: 18px;
    line-height: 9px;
    text-align: center;
    display: inline-block;
    font-size: 14px;
    margin-left: 0.25em;
  }

  span {
    pointer-events: none;
  }

  .applied-filter-color {
    width: 1.25rem;
    height: 1.25rem;
    display: none;
    margin-right: 10px;
  }
}

.wall {
  // Show menu Class
  .show-menu {
    outline: none;
    height: 100vh;
    overflow-y: hidden;

    .cbg-cmp-wall__container-filter {
      height: 100%;
      width: 100%;
      transition: width 0.45s ease;
      left: 0;
      padding: 1rem 1.3rem 0 1.3rem;
      background: white;
      position: fixed;
      top: 0;
      z-index: 20;
      display: flex;
      flex-direction: column;
    }

    .filter-options {
      @media screen and (min-width: 850px) {
        height: 100%;
      }
    }

    @media screen and (min-width: $screen-lg) {
      display: none;
    }
  }

  // Close mobile menu button
  .close-menu {
    padding: 0;
    outline: none;
    transition: all 4.5s ease;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    background: $platform-full-width-btn;
    display: block;
    position: relative;

    @media screen and (min-width: $screen-lg-md) {
      display: none;
    }
  }

  .close-menu:after {
    content: "X";
    font-size: 1.5rem;
    position: absolute;
    width: 100%;
    top: 5px;
    left: 0;
    color: $platform-dark-blue;
  }

  // Clear Menu Button on Desktop
  .clear-menu {
    text-decoration: none;
    color: $platform-gray-text;
    outline: $platform-full-width-btn;
    display: none;
    background: none;
    padding: 0;
    border-radius: 0;

    @media screen and (min-width: $screen-lg-md) {
      display: block;
    }
  }

  // Wall Component
  .cbg-cmp-wall {
    display: flex;
    box-sizing: border-box;
    margin-top: 2.6rem;
    padding: 1rem 1.3rem;
    //font-family: $font-ibm;

    .extra-info {
      display: flex;

      @media screen and (max-width: $screen-sm) {
        flex-direction: column;
      }
    }

    .button--user-action {
      font-family: $font-ibm;
      font-size: $font-size;
      font-weight: 600;
      text-decoration: none;
      color: $platform-gray-text;
      position: relative;
      cursor: pointer;

      &:after {
        display: block;
        content: "";
        width: 100%;
        height: 2px;
        background-color: $platform-dark-gray;
        position: absolute;
        bottom: 0;
      }
    }

    // Mobile filter menu button
    .button--filter {
      width: fit-content;
      min-width: 9.4rem;
      outline: none;
      @media screen and (min-width: $screen-lg-md) {
        display: none;
      }

      .icon,
      .icon--filter {
        display: inline-block;
        width: 13px;
        height: 13px;
        background-size: cover;
      }
    }

    // Wall Component's filter cylinders
    .filters {
      padding-top: 1.9rem;
      min-height: 80px;
      display: flex;
      flex-wrap: wrap;
      max-width: fit-content;
      gap: 10px;
    }

    @media screen and (min-width: $screen-sm) {
      margin: auto;
    }

    @media screen and (min-width: $screen-md) {
      width: 100%;
      max-width: 1220px;
      padding: 80px 1.3rem 1rem;
    }
  }

  // Side Menu that contains filter options
  .cbg-cmp-wall__container-filter {
    width: 0;
    transition: all 0.45s ease;
    position: absolute;
    z-index: 1;
    overflow-x: hidden;
    left: 0;
    background: white;
    max-height: 200rem;

    .filter-options {
      padding-top: 1.9rem;
      padding-bottom: 4rem;
    }

    .wall__container-filter--header {
      justify-content: space-between;
      min-height: 36px;
      align-items: center;
    }

    @media screen and (min-width: $screen-lg-md) {
      display: block;
      width: 100%;
      max-width: $wall-sidebar-width;

      overflow-x: visible;
      margin-right: 1.5rem;
      height: fit-content;
      top: 0;
      position: sticky;

      .filter-options {
        height: 100vh;
        overflow-y: hidden;
        display: inline-block;
      }
      .filter-options:hover {
        overflow-y: auto;
      }
    }
  }

  // Main Content for Wall Dummy
  .cbg-cmp-wall__container-primary {
    // min-width: calc(#{$screen-xs} - 2rem);
    width: 100%;

    .results--msg {
      font-family: $font-ibm;
      font-size: $font-size;
      font-weight: 300;
      text-decoration: none;
      color: $platform-gray-text;
    }

    .grid--wall__item {
      padding: 2rem 0;
      border-bottom: 2px solid $platform-gray;
    }

    @media screen and (min-width: $screen-lg-md) {
      max-width: calc(100% - #{$wall-sidebar-width});
    }
  }

  // Bottom Filter Menu buttons
  .mobile-bottom-menu-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    background: $porcelain;
    width: 100vw;
    transform: translateX(-20px);
    max-height: 5rem;
    height: 100%;
    align-items: center;
    padding: 0.5rem 0;
    margin-top: auto;

    button {
      height: 3rem;
      width: 10rem;
      border: 2px solid $platform-full-width-btn;
      border-radius: 25px;
      padding: 0;
    }

    .apply-filter-btn {
      background: $platform-full-width-btn;
      padding: 0px;
    }

    @media screen and (min-width: $screen-lg-md) {
      display: none;
    }
  }

  // Styles for Wall component's grid
  .grid--wall {
    overflow: hidden;

    .button-content {
      display: block;
      margin: 0 20px;

      svg {
        height: 20px;
        width: 20px;
        margin: 2px 10px 0 10px;
        vertical-align: middle;
      }
    }
  }

  .grid--wall__ratings-container {
    margin-top: 8px;
  }

  .wall-sorted-results {
    display: flex;
    flex-direction: column-reverse;
    @media screen and (min-width: $screen-lg-md) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  .wall-content-results-left {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 21px;
    border-bottom: 2px solid $platform-image-upload-border;
    margin-bottom: 20px;
    @media screen and (min-width: $screen-lg-md) {
      padding-bottom: 0;
      border-bottom: none;
      margin-bottom: 0;
    }
  }

  // Color Sort Dropdown
  .wall-content-dropdown {
    select {
      appearance: none;
      background-image: url(../resources/images/svgs/chevron-sort.svg);
      background-position: 100%;
      background-size: 1.6rem;
      background-repeat: no-repeat;
      height: 2.8rem;
      width: 10rem;
      box-sizing: border-box;
      border: 2px solid $platform-image-upload-border;
      color: $platform-dark-blue;
      font-size: $font-size;
      font-weight: 500;
      padding-left: 12px;
      outline: none;
      @media screen and (min-width: $screen-lg-md) {
        height: 3rem;
        width: 11rem;
        background-position: 93%;
      }
    }

    select:hover,
    select:focus,
    select:active {
      border: 2px solid $platform-wall-dropdown-menu-border;
    }

    label {
      display: none;
      @media screen and (min-width: $screen-lg-md) {
        display: inline-block;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0.25px;
        padding-right: 8px;
      }
    }
  }

  // Load more Button
  .button-container__load-more {
    display: none;

    a {
      cursor: pointer;
    }

    &.visible {
      display: block;
    }
  }

  &.hide-filters {
    .cbg-cmp-wall__container-primary {
      margin: 0 auto;
    }

    .cbg-cmp-wall__container-filter,
    .button--filter.display-ib {
      display: none;
    }
  }
}

.html-hide-overflow {
  overflow: hidden;

  .mobile-filter-buttons-container {
    z-index: 50;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 0.5rem 0;
    transform: none;
    position: fixed;
    bottom: 0;
    width: 100vw;
    height: auto;
    background-color: white;
  }
}
